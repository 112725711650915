/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    highlights: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M16 8A8 8 0 100 8a8 8 0 0016 0m-8 5v1H4.5a.5.5 0 00-.093.009A7 7 0 013.1 13zm0-1H2.255a7 7 0 01-.581-1H8zm-6.71-2a7 7 0 01-.22-1H8v1zM1 8q0-.51.07-1H8v1zm.29-2q.155-.519.384-1H8v1zm.965-2q.377-.54.846-1H8v1zm2.137-2A6.97 6.97 0 018 1v1z"/>',
    },
});
